/* ＝＝＝共通＝＝＝ */
.App {
  width: 100%;
  font-size: 18px;
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

html {
  scroll-behavior: smooth; /* パーマリンクをスムーズにする */
}

/* コンポーネントごとに区切り線 */
.line {
  margin: 50px 0px 50px 0px;
  width: 100%;
  height: 1px;
}

.Mission_line1 {
  margin: 30px 0px 30px 0px;
  width: 100%;
  height: 1px;
}

.line line {
  stroke: black;
  stroke-width: 1;
}

.empty {
  white-space: pre; /* 要素内の空白をそのまま表示 */
}

/* アンカーリンクの位置を指定（100px下に設定しないとスクロールした際に中途半端な位置で止まる） */
div.anchor {
  position: relative;
  top: -20px; /* 調整したい値に応じてマイナスの値を設定 */
}

/* ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */

/* ＝＝＝ヘッダー＝＝＝ */
header {
  display: flex; /* 子要素を水平に配置 */
  flex-direction: column;
  align-items: center;
  height: 80px; /* 高さを画面全体に設定 */
  background-color: #ffffff;
  color: #0b0b0b;

  /* navをスクロールしている最中に固定*/
  position: fixed; /* ナビゲーションバーを固定位置に配置 */
  top: 0; /* 画面の上部に配置 */
  width: 100%; /* 画面幅いっぱいに設定 */
  z-index: 1000; /* 他の要素よりも手前に表示 */
}

.header2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

h3 {
  letter-spacing: 1px; /* 文字間隔を2pxに設定 */
  min-width: 300px; /* 画像の最小幅を100pxに設定 */
}

.HeaderLogo {
  max-width: 4%;
  min-width: 40px;
}

/* ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */

/* ＝＝＝フッダー＝＝＝ */
footer {
  display: flex;
  align-items: center;
  height: 70%; /* 高さを画面全体に設定 */
  background: linear-gradient(
    to top,
    rgb(175, 174, 174),
    rgba(154, 152, 152, 0)
  );
  flex-direction: column;
  margin-top: 60px;
}

.Footer2 {
  margin-top: 100px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 90%;
}

.copyRight {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  letter-spacing: 1px; /* 文字間隔を2pxに設定 */
  margin-bottom: 6px;
}

.FooterLogo {
  max-width: 4%;
  min-width: 40px;
}

/* ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */

/* ＝＝＝ヘッダー・フッダー共通＝＝＝ */
.left-elements {
  display: flex;
  gap: 8px; /*左寄せの要素間の間隔を設定 */
  align-items: center;
  width: 20%;
}

nav ul li a,
nav ul li {
  list-style: none;
  display: inline-block;
  text-decoration: none; /*箇条書きの・を削除*/
  font-family: "Noto Sans JP", sans-serif;
  padding-left: 10px;
  color: #020202;
  letter-spacing: 0.3px; /* 文字間隔を0.3pxに設定 */
}

nav ul li a:hover {
  color: rgb(211, 46, 240);
}

/* ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */

/*TOP画像*/
.homeImg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  min-width: 200px; /* 画像の最小幅を設定 */
  width: 80%;
  margin-top: 80px; /* ナビゲーションバーの幅より下に画像が表示されるようにする */
}

/*ContactSubとRecruitSub画像*/
.ContactSubImg,
.RecruitSubImg,
.CoreValueSubImg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  width: 30%;
}

.SubImg {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */

/*＝＝＝TOPページ共通仕様＝＝＝*/
.About,
.News,
.Mission,
.Member,
.Contact,
.Recruit,
.CoreValue {
  display: flex;
  justify-content: center;
  align-items: center;
}

.About_section,
.News_section,
.Mission_section,
.Member_section,
.Contact_section,
.Recruit_section,
.CoreValue_section {
  display: block;
  list-style: none;
  margin-top: 14px;
  align-items: center;
  width: 30%; /* 画像とsectionの縦のラインをそろえるため */
}

/* 右側の2つのSectionを縦に並べる */
.Contact_section,
.Recruit_section,
.Mission_section,
.CoreValue_section {
  text-align: center;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.About_section li,
.Mission_section li,
.Member_section li,
.Contact_section li,
.Recruit_section li {
  margin: 5px;
  line-height: 160%;
}

/* ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */

/* ＝＝＝アニメーション画像＝＝＝ */
.Section_img {
  opacity: 0;
  transform: translateX(-100%); /* 初期位置を左側の外側に設定 */
  transition: opacity 1s ease, transform 1s ease; /* アニメーションの設定 */
  display: block;
  width: 26%;
  margin: 0px 30px 0px 30px;
}

/* ImageWithAnimationコンポーネントのCSS */
.Section_img.show {
  opacity: 1;
  transform: translateX(0); /* 表示されるときに右に移動 */
}

/* ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */

/* ＝＝＝Memberコンポーネント＝＝＝ */
.Member_photo {
  display: block;
  margin: 0 auto; /*要素を水平方向に中央揃え*/
  margin-top: 14%;
}

.Member_text {
  margin: 0px;
}

.Member_section {
  width: 30%;
  padding: 20px;
  box-sizing: border-box; /* パディングとボーダーを含めた幅と高さを設定 */
}

/* ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */

/* Aboutコンポーネントの会社名 */
#About_section_name {
  letter-spacing: 1px; /* 文字間隔を2pxに設定 */
}

/* 「|」の位置を合わせる */
.About_section .term {
  text-align: right; /*「|」の位置を右に寄せて縦が揃っているようにする　*/
  min-width: 76px; /*　項目名の横幅を固定して画面の大きさが変わっても「｜」の位置が変わらないようにする　*/
}

/* 項目名と項目内容を1行とし、右寄せの状態にし自動改行する
項目名は最低幅を指定しているのでおのずと項目内容のみ改行される */
.About_section_1,
.About_section_2,
.About_section_3,
.About_section_4,
.About_section_5,
.About_section_6 {
  display: flex;
  white-space: pre-wrap;
  align-items: right;
  margin: 10px;
}

/* ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */

/* Missionコンポーネントのタイトル */
.Mission_title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}

.Mission {
  margin-top: 60px;
}

.CoreValue_content,
.CoreValue_subTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.CoreValue_title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px;
}

.sub {
  margin: 10px 0px 55px 0px;
}

.CoreValue_p {
  max-width: 340px;
  text-align: left;
  width: 100%; /* 必要に応じて調整 */
  margin: 10px 0 10px 24px;
}

.CoreValue_p b {
  font-size: 1.35rem;
}

/* ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */

/* ＝＝＝問い合わせフォーム共通＝＝＝ */
.formSub p {
  margin: 4% 0% 4% 0%;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */

.News_section li {
  margin: 10px;
}

.News_section span {
  color: #555555;
}

/* ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */

.News ul,
nav ul {
  padding: 0;
}

/* ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */

@media screen and (max-width: 1400px) {
  /*レスポンシブデザイン*/

  nav li,
  nav ul li a {
    display: block;
    padding: 4px;
    font-size: 14px;
  }
}

@media screen and (max-width: 1050px) {
  /*レスポンシブデザイン*/

  .App {
    font-size: 16px; /*全体の文字の大きさ*/
  }

  header {
    position: static;
    height: 20%;
  }

  .header2,
  .Footer2 {
    width: 90%;
  }

  .homeImg {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
  }

  .ContactSubImg,
  .RecruitSubImg,
  .CoreValueSubImg {
    min-width: 60%;
  }

  .News_section,
  .Mission_section,
  .Member_section,
  .Contact_section,
  .Recruit_section,
  .CoreValue_section {
    max-width: 100%;
    width: 90%;
    line-height: 150%;
    text-align: center;
    margin: 10px;
  }

  /* 左寄せで自動改行 */
  .Member_section,
  .News_section,
  .About_section {
    margin: 40px 10px 10px 10px; /* 画像のデザインの影響でSectionの画像の幅が狭く見える */
    width: 72%;
  }
  .Member_section,
  .About_section li,
  .News_section li {
    min-width: 70%;
    text-align: left;
    white-space: pre-wrap;
  }

  .Section_img {
    min-width: 60%;
  }

  .About,
  .News,
  .Mission,
  .Member,
  .Contact,
  .Recruit,
  .CoreValue {
    flex-direction: column;
    display: flex;
    align-items: center;
  }

  .Member_photo {
    margin-top: 10%;
  }

  header h3 {
    font-size: 18px;
    width: 300px;
  }

  .Mission_title,
  .CoreValue_title {
    font-size: 20px;
  }

  nav ul {
    text-align: right;
  }

  .HeaderLogo {
    max-width: 2%;
    min-width: 32px;
  }
}

@media screen and (max-width: 530px) {
  /*レスポンシブデザイン*/

  /* 左寄せで自動改行 */
  .Member_section,
  .News_section,
  .About_section {
    width: 90%;
  }

  header h3 {
    font-size: 16px;
    width: 300px;
    margin-bottom: 18px;
  }

  /* サイドバーの修正 */
  .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    width: 130px;
  }

  .Mission {
    margin-top: 30px;
  }

  .CoreValue_p {
    max-width: 300px;
    text-align: left;
    width: 100%; /* 必要に応じて調整 */
    margin: 10px 0 10px 24px;
  }
  .CoreValue_p b {
    font-size: 1.2rem;
  }
}
